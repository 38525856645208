<!-- eslint-disable -->
<template>
  <div>
    <div
      v-if="this.userRole === 'admin'"
    >
      <client-tabs
        :user-role="userRole"
      />
    </div>
    <div
      v-else
    >
      <h1>Family</h1>
      <div class="page-subtitle">Patient's family members registered in the system.</div>
    </div>

    <div
      :class="backdrop"
      style="z-index:1032;"
    />
    <b-row class="d-flex flex-row pl-0">
      <b-col
        id="tableColumnForm"
        :cols="tableColumn"
      >
        <family-list
          ref="FamilyList"
          :list="this.familyData"
          :total-family-list="this.totalFamilyList"
          :current-family-page="this.currentFamilyPage"
          :user-role="this.userRole"
          :family-id="this.familyId"
        />
      </b-col>
      <b-col
        :cols="addFormColumn"
      >
        <transition name="fade">
          <add-family
            v-if="this.showAddForm == 'true'"
            id="addClientForm"
            ref="addFamily"
            :key="componentKey"
            :class="showForm"
            :client-id="this.clientId"
            :family-details="this.familyDetails"
            :form-title="this.formTitle"
          />
        </transition>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
// import JQuery from 'jquery'

import { BRow, BCol } from 'bootstrap-vue'
import AddFamily from './AddFamily.vue'
import FamilyList from './FamilyList.vue'
import clientsStoreModule from '../../apps/clients/clientsStoreModule'
import ClientTabs from './ClientTabs.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    AddFamily,
    FamilyList,
    BRow,
    BCol,
    clientsStoreModule,
    ClientTabs,
  },
  data() {
    return {
      clientId: this.$route.query.id,

      pageTitle: 'Family',
      tableColumn: '12',
      addFormColumn: '4',
      showForm: 'hidden',
      backdrop: '',
      showAddForm: false,
      componentKey: 0,
      familyData: [],
      familyDataStatic:[],
      familyDetails:[],
      totalFamilyList: 0,
      currentFamilyPage: 1,
      familyStatus: '',
      formTitle: 'Add Family',
      userRole: '',
      familyId: '',
    }
  },
  setup() {
    const userData = JSON.parse(localStorage.userData)
    const userRole = userData.role

    return {
      userRole
    }
  },
  created() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.userRole = user.role
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.userRole !== 'admin') {
        const tempClientId = JSON.parse(localStorage.getItem('userData'))
        this.clientId = tempClientId.clientId
        this.familyId = tempClientId.familyId
      }
      const formdata = {
        clientId: this.clientId,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/getFamilyDataAction', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        this.familyData = response.data.familyData
        this.familyDataStatic = response.data.familyData
        this.totalFamilyList = this.familyDataStatic.length
      }, response => {
        //Destroy session
        if(response.status !== 200) {
          this.$router.push({ name: 'login' })
        }
      })
      .catch(error =>  {
          console.log(error.response.status)
      })
    },
    openForm(param,id) {
      if(param == 'add'){
        this.tableColumn = '12'
        this.addFormColumn = '4';
        this.showForm = 'visible'
        this.showAddForm = 'true'
        this.backdrop = 'b-sidebar-backdrop bg-dark'
        this.formTitle = "Add Family"
      }
      if(param == 'update'){
        this.tableColumn = '12'
        this.addFormColumn = '4';
        this.showForm = 'visible'
        this.showAddForm = 'true'
        this.backdrop = 'b-sidebar-backdrop bg-dark'
        this.formTitle = "Update Family"
      }
    },
    closeForm() {
      this.tableColumn = '12'
      this.showAddForm = 'false'
      this.backdrop = ''
    },
    rerenderForm() {
      this.componentKey += 1
      this.showForm = 'visible'
    },
    removeFamily(id){
       const formdata = {
        id: id,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/removeFamilyDataAction', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
          Swal.fire({
          icon: 'success',
          title: 'Removed Succesfully',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.fetchData()
      })
    },
    fetchFamilyDetails(id) {
      let familyDetails = this.familyData.filter(item => item.id === id)
      this.familyDetails = familyDetails
    },
    filterBySearch(search){
      this.currentFamilyPage = 1
      this.familyData = this.familyDataStatic
      this.familyData = this.getMatchingResults(search)
      this.totalFamilyList = this.familyData.length
    },
    getMatchingResults(search) {
       return this.familyDataStatic.filter(
        family =>
        (
          family.name.toLowerCase().includes(search.toLowerCase())) 
        )
    },
  },
}
</script>

<style>
h1{
  margin: auto;
}
.fade-enter-from{
  opacity: 0;
}
.fade-enter-to{
  opacity: 1;
}
.fade-leave-active{
  transition: all 0.3s ease;
}
.fade-leave-from{
  opacity: 1;
}
.fade-leave-to{
  opacity: 0;
}
.fade-leave-active{
  transition: all 0.3s ease;
}
.breadcrumb-wrapper{
  display: inline-block;
  /* position: fixed; */
  margin-top: 4px;
}
.page-subtitle {
  margin: 30px 0 15px 0;
  font-weight: 700;
}
</style>
