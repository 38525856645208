<!-- eslint-disable -->
<template>
  <section
    v-if="this.addForm == true"
    id="family-basic-input"
  >
    <validation-observer ref="simpleRules">
      <b-form class="easein">
        <b-row>
          <b-col md="12">
            <div class="card">
              <div class="card-header form d-flex flex-row ">
                <b-col class="pl-0 ">
                  <p class="card-title">
                    {{ formTitle }}
                  </p>
                </b-col>
                <b-col class="pr-0">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    @click.prevent="closeForm()"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </b-col>
              </div>
              <div class="card-body add-form-family">
                <b-row>
                  <b-col
                    v-for="(item,index) in this.familyData"
                    :key="index"
                    lg="12"
                    class="mb-1"
                  >
                    <!-- INPUT FOR TEXT TYPE -->
                    <b-form-group
                      v-if="item.type == 'text'"
                      :label="item.label"
                      :label-for="item.bind"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.label"
                        rules="required"
                      >
                        <b-form-input
                          id="item.bind"
                          v-model="fields[item.bind]"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="item.label"
                          lg="12"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-if="item.type == 'text-nonreq'"
                      :label="item.label"
                      :label-for="item.bind"
                    >
                      <b-form-input
                        id="item.bind"
                        v-model="fields[item.bind]"
                        :placeholder="item.label"
                        lg="12"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="item.type == 'number'"
                      :label="item.label"
                      :label-for="item.bind"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.label"
                        rules="required"
                      >
                        <b-form-input
                          :id="item.bind"
                          v-model="fields[item.bind]"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="item.label"
                          v-mask="['######']"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-if="item.type == 'email'"
                      :label="item.label"
                      :label-for="item.bind"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.label"
                        rules="required|email"
                      >
                        <b-form-input
                          id="email-address"
                          v-model="fields[item.bind]"
                          :state="errors.length > 0 ? false : null"
                          type="email"
                          placeholder="Email Address"
                          lg="12"
                          :disabled="isDisabled === 1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- INPUT FOR SELECT -->
                    <b-form-group
                      v-if="item.type == 'select' && item.bind=='relationship'"
                      :label="item.label"
                      :label-for="item.bind"
                      class="mb-0"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.label"
                        rules="required"
                      >
                        <b-form-group
                          class="mb-0"
                        >
                          <!-- <v-select
                            :id="item.bind"
                            v-model="fields[item.bind]"
                            item-text="text"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="relationshipOptions"
                            class="mb-0"
                            placeholder=""
                          />
                          <template #selected-option="{ label }">
                            <span class="text-truncate overflow-hidden">
                              {{ label }}
                            </span>
                          </template> -->
                          <b-form-select
                            :id="item.bind"
                            v-model="fields[item.bind]"
                            :state="errors.length > 0 ? false : null"
                            :options="relationshipOptions"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-if="item.type == 'select' && item.bind=='type'"
                      :label="item.label"
                      :label-for="item.bind"
                      class="mb-0"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.label"
                        rules="required"
                      >
                        <b-form-group
                          class="mb-0"
                        >
                          <!-- <v-select
                            :id="item.bind"
                            v-model="fields[item.bind]"
                            item-text="text"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="typeOptions"
                            class="invoice-filter-select mb-0"
                            placeholder=""
                            append-to-body
                            :calculate-position="withPopper"
                          />
                          <template #selected-option="{ label }">
                            <span class="text-truncate overflow-hidden">
                              {{ label }}
                            </span>
                          </template> -->
                          <b-form-select
                            :id="item.bind"
                            v-model="fields[item.bind]"
                            :state="errors.length > 0 ? false : null"
                            :options="typeOptions"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- INPUT FOR PHONE NUMBER -->
                    <div
                      v-if="item.type == 'textCheckbox'"
                      class="mb-1"
                    >
                      <label
                        class="form-label"
                        for="helperText"
                      >{{ item.label }}</label>
                      <div class="d-flex flex-row">
                        <b-form-input
                          :id="item.bind"
                          v-model="fields[item.bind]"
                          v-mask="['(###) ###-####']"
                        />
                        <div class="form-check form-check-inline float-right ml-2 ">
                          <b-form-checkbox
                            v-if="item.contact === 'homePhonePrimary'"
                            v-model="fields.primarycontactnumber"
                            value="homephone"
                            class="custom-control-primary"
                          >
                            PRIMARY
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-if="item.contact === 'mobilePhonePrimary'"
                            v-model="fields.primarycontactnumber"
                            value="mobilephone"
                            class="custom-control-primary"
                          >
                            PRIMARY
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-if="item.contact === 'workPhonePrimary'"
                            v-model="fields.primarycontactnumber"
                            value="workphone"
                            class="custom-control-primary"
                          >
                            PRIMARY
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="item.type == 'checkbox'"
                      class="mb-1"
                    >
                      <b-form-checkbox
                        v-model="fields[item.bind]"
                        class="custom-control-primary"
                      >
                        {{ item.label }}
                      </b-form-checkbox>
                    </div>
                  </b-col>
                  <b-col lg="12" class="mb-2">
                    <b-button
                      variant="secondary"
                      type="submit"
                      class="mr-1"
                      @click.prevent="validationForm"
                    >
                      Save
                    </b-button>
                    <b-button
                      variant="outline-dark"
                      type="cancel"
                      @click.prevent="closeForm"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </section>
</template>
<script>
/* eslint-disable */
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormSelect,
    // vSelect,
  },
  directives: {
    mask,
  },
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    familyDetails: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      pageTitle: 'Family',
      addForm: true,
      hidden: 'hidden1',
      name: '',
      required,
      relationshipOptions: [
        'Mother',
        'Father',
        'Son',
        'Daughter',
        'Grand Parent',
        'Brother',
        'Sister',
        'Spouse',
        'Cousin',
        'Stepbrother',
        'Stepsister',
      ],
      typeOptions: [
        'Person-In-Charge',
        'Power of Attorney',
        'HealthCare Directed',
        // 'Guest Account',
      ],
      componentKey: 0,
      fields: {
        firstName: '',
        middleName: '',
        lastName: '',
        homePhone: '',
        mobilePhone: '',
        workPhone: '',
        email: '',
        homePhonePrimary: false,
        workPhonePrimary: false,
        mobilePhonePrimary: false,
        primarycontactnumber: '',
        relationship: '',
        type: '',
      },
      clientId: this.$route.query.id,
      userRole: '',
      isDisabled: 0,
    }
  },
  setup() {
    return {
    }
  },
  computed: {
    familyData() {
      return [
        {
          id: '', class: '', label: 'First Name *', type: 'text', value: '', placeholder: '', bind: 'firstName', required: true,
        },
        {
          id: '', class: '', label: 'Middle Name', type: 'text-nonreq', value: '', placeholder: '', bind: 'middleName', required: false,
        },
        {
          id: '', class: '', label: 'Last Name *', type: 'text', value: '', placeholder: '', bind: 'lastName', required: true,
        },
        {
          id: '', class: '', label: 'Email Address *', type: 'email', value: '', placeholder: '', bind: 'email', required: true,
        },
        {
          id: '', class: '', label: 'Home Phone', type: 'textCheckbox', value: '', placeholder: '', bind: 'homePhone', contact: 'homePhonePrimary', required: false,
        },
        {
          id: '', class: '', label: 'Mobile Phone', type: 'textCheckbox', value: '', placeholder: '', bind: 'mobilePhone', contact: 'mobilePhonePrimary', required: false,
        },
        {
          id: '', class: '', label: 'Work Phone', type: 'textCheckbox', value: '', placeholder: '', bind: 'workPhone', contact: 'workPhonePrimary', required: false,
        },
        {
          id: '', class: '', label: 'Relationship to the Client *', type: 'select', value: '', placeholder: '', bind: 'relationship', required: true,
        },
        {
          id: '', class: '', label: 'Role *', type: 'select', value: '', placeholder: '', bind: 'type', required: true,
        },
      ]
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.userRole = user.role

    if (this.userRole !== 'admin') {
      this.clientId = user.clientId
    }

    if (this.formTitle === 'Update Family') {
      this.fields.firstName = this.familyDetails[0].firstname
      this.fields.middleName = this.familyDetails[0].middlename
      this.fields.lastName = this.familyDetails[0].lastname
      this.fields.homePhone = this.familyDetails[0].homephone
      this.fields.mobilePhone = this.familyDetails[0].cellphone
      this.fields.workPhone = this.familyDetails[0].workphone
      this.fields.homePhonePrimary = this.familyDetails[0].homePhonePrimary
      this.fields.workPhonePrimary = this.familyDetails[0].workPhonePrimary
      this.fields.mobilePhonePrimary = this.familyDetails[0].mobilePhonePrimary
      this.fields.primarycontactnumber = this.familyDetails[0].primarycontactnumber
      this.fields.email = this.familyDetails[0].email
      this.fields.relationship = this.familyDetails[0].relationship
      this.fields.type = this.familyDetails[0].type
      this.isDisabled = 1
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.formTitle === 'Add Family') {
            this.addFamilyData()
          }
          if (this.formTitle === 'Update Family') {
            this.updateFamilyData(this.familyDetails[0].id)
          }
        }
      })
    },
    closeForm() {
      this.addForm = false
      this.$parent.closeForm()
    },
    openForm() {
      this.addForm = true
    },
    addFamilyData() {
      const formData = {
        clientId: this.clientId,
        firstName: this.fields.firstName,
        middleName: this.fields.middleName,
        lastName: this.fields.lastName,
        homePhone: this.fields.homePhone,
        mobilePhone: this.fields.mobilePhone,
        workPhone: this.fields.workPhone,
        email: this.fields.email,
        relationship: this.fields.relationship,
        type: this.fields.type,
        homePhonePrimary: this.fields.homePhonePrimary,
        workPhonePrimary: this.fields.workPhonePrimary,
        mobilePhonePrimary: this.fields.mobilePhonePrimary,
        primarycontactnumber: this.fields.primarycontactnumber,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/addFamilyDataAction', {
        param: formData,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          if (response.data.alert) {
            Swal.fire({
              icon: 'warning',
              title: response.data.alert,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } else {
            this.addForm = false
            this.$parent.closeForm()
            Swal.fire({
              icon: 'success',
              title: 'Added Succesfully',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$parent.fetchData()
          }
        })
    },
    updateFamilyData(id) {
      const formData = {
        id: id,
        clientId: this.clientId,
        firstName: this.fields.firstName,
        middleName: this.fields.middleName,
        lastName: this.fields.lastName,
        homePhone: this.fields.homePhone,
        mobilePhone: this.fields.mobilePhone,
        workPhone: this.fields.workPhone,
        email: this.fields.email,
        relationship: this.fields.relationship,
        type: this.fields.type,
        homePhonePrimary: this.fields.homePhonePrimary,
        workPhonePrimary: this.fields.workPhonePrimary,
        mobilePhonePrimary: this.fields.mobilePhonePrimary,
        primarycontactnumber: this.fields.primarycontactnumber,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/updateFamilyDataAction', {
        param: formData,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          if (response.data.alert) {
            Swal.fire({
              icon: 'warning',
              title: response.data.alert,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          else {
            this.addForm = false
            this.$parent.closeForm()
            Swal.fire({
              icon: 'success',
              title: 'Updated Succesfully',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$parent.fetchData()
          }
        })
    },
  },
}
</script>

<style scoped>
@import './css/client.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.ql-container.ql-snow {
    border-bottom: none!important;
}
.custom-select option {
  padding: 15px !important;
}
.custom-select option:hover, .custom-select option:active {
  background: #569099 !important;
}
</style>
