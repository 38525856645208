<!-- eslint-disable -->
<template>

  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="9"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3 class="pr-1">
            Family
          </h3>
          <b-button
            v-if="userRole === 'admin'"
            id="addClientBtn"
            variant="primary"
            @click.prevent="openForm()"
          >
            <feather-icon
              icon="UserPlusIcon"
              size="16"
              class="mx-1"
            />
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Find Family"
              @input="filterBySearch($event)"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refFamilyListTable"
      :items="list"
      responsive
      :fields="tableColumns"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
      :per-page="perPage"
      :current-page="currentTablePage"
      @sort-changed="handleSortChange($event.sortDesc)"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Family -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(`${data.item.firstname.slice(0,1)} ${data.item.lastname.slice(0,1)}`)"
              :variant="`${resolveFamilyAvatarVariant(data.item.invoiceStatus)}`"
              rounded
            />
          </template>
          <span
            class="text-primary"
            :class="userRole === 'admin' ? 'font-weight-bold d-block text-nowrap cursor-pointer name-spacing' : 'font-weight-bold d-block text-nowrap name-spacing' "
          >
            {{ data.item.firstname }}  {{ data.item.lastname }}
          </span>
        </b-media>
      </template>
      <!-- Column: Email Address -->
      <template #cell(email)="data">
        <b-media vertical-align="center">
          <span class=" d-block text-nowrap">
            {{ data.item.email }}
          </span>
        </b-media>
      </template>
      <!-- Column: Contact Information -->
      <template #cell(contact_number)="data">
        <b-media vertical-align="center">
          <span class=" d-block text-nowrap">
            <div
              v-if="data.item.homephone || data.item.cellphone || data.item.workphone"
            >
              <div
                v-if="data.item.homephone"
              >
                H: {{ data.item.homephone }}
              </div>
              <div
                v-if="data.item.cellphone"
              >
                M: {{ data.item.cellphone }}
              </div>
              <div
                v-if="data.item.workphone"
              >
                W: {{ data.item.workphone }}
              </div>
            </div>
            <div
              v-else
            >
              N/A
            </div>
          </span>
        </b-media>
      </template>
      <!-- Column: Relationship -->
      <template #cell(relationship)="data">
        <b-media vertical-align="center">
          <span class="d-block text-nowrap">
            {{ data.item.relationship }}
          </span>
        </b-media>
      </template>
      <!-- Column: Type -->
      <template #cell(type)="data">
        <b-media vertical-align="center">
          <div
            v-if="data.item.type === 'Person-In-Charge' || data.item.type === 'Power of Attorney' || data.item.type === 'HealthCare Directed'"
          >
            <span class="type-pic d-block text-nowrap">
              {{ data.item.type }}
            </span>
          </div>
          <div
            v-else
          >
            <span class="type-other d-block text-nowrap">
              {{ data.item.type }}
            </span>
          </div>
        </b-media>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div
          v-if="userRole === 'admin'"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="editFamily(data.item.id)"
              >
                <span>Edit</span>
              </b-dropdown-item>
              <!--
              <b-dropdown-item
                v-if="data.item.type === 'Person-In-Charge'"
                v-b-modal.modal-removePIC
                @click="tempFamilyData = data.item"
              >
                <span>Remove as PIC</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.type === 'Power of Attorney'"
                v-b-modal.modal-removePIC
                @click="tempFamilyData = data.item"
              >
                <span>Remove as POA</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.type === 'HealthCare Directed'"
                v-b-modal.modal-removePIC
                @click="tempFamilyData = data.item"
              >
                <span>Remove as HCD</span>
              </b-dropdown-item>
              -->
              <b-dropdown-item
                @click="removeFamily(data.item.id)"
              >
                <span>Delete</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="resetAccount(data.item)"
              >
                <span>Reset Account</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </div>
        <!--
        <div
          v-else
        >
          <div
            v-if="data.item.id == familyId"
            class="text-center"
          >
            <feather-icon
              :id="`row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mr-1"
              role="button"
              @click="editFamily(data.item.id)"
            />
          </div>
        </div>
        -->
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ (this.currentTablePage-1)*6+1 }} to <span v-if="this.currentTablePage < this.totalFamilyList/6">{{ this.currentTablePage*6 }}</span> <span v-if="this.currentTablePage > this.totalFamilyList/6">{{ this.totalFamilyList }}</span> of {{ this.totalFamilyList }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentTablePage"
            :total-rows="totalFamilyList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>

    <!-- Remove PIC Modal  -->
    <b-modal
      id="modal-removePIC"
      ref="removePIC-modal"
      title="Remove as PIC"
      centered
      no-close-on-backdrop
      @show="initializeNewPICList"
    >
      <div class="mx-0.5 my-1">
        <b-card-text>
          You are about to remove <b> {{ `${tempFamilyData.firstname} ${tempFamilyData.lastname}` }} </b> as a Family Member ( {{ `${tempFamilyData.type}` }} ) of <span class="font-weight-bold">{{ this.clientInfo }}</span>.<br>
          Select the new PIC/POA/HCD from the list below:<br>
        </b-card-text>
        <b-form-group
          label="Choose new PIC/POA/HCD"
          label-for="vue-select"
          class="mt-1"
        >
          <v-select
            id="vue-select"
            v-model="newPIC"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="this.newPICList"
            :reduce="(option) => option.id"
          />
        </b-form-group>
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="secondary"
          :disabled="newPIC === ''"
          @click="removePIC(tempFamilyData.id)"
        >
          Remove
        </b-button>
      </template>
    </b-modal>

  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BPagination, BTooltip, BDropdown, BDropdownItem, BModal, BCardText, BFormGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import { sortCompare } from '@/@fake-db/utils'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useFamilyLists from './js/useFamilyList'
import { ref } from '@vue/composition-api'

import clientStoreModule from './js/clientStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    vSelect,
    Swal,
    sortCompare,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BModal,
    BCardText,
    BFormGroup,
  },
  data() {
    return {
      familyData: this.list,
      currentTablePage: this.currentFamilyPage,
      newPIC: '',
      newPICList: [],
      clientInfo: '',
    }
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    totalFamilyList: {
      type: Number,
      required: true,
    },
    currentFamilyPage: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
    familyId: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const tempFamilyData = ref({})

    // Table Handlers
    let tableColumns = [
      { key: 'name', sortable: true },
      { key: 'email' },
      { key: 'contact_number' },
      { key: 'relationship' },
      { key: 'type', label: 'Role' },
    ]

    if (props.userRole === 'admin'){
      tableColumns.push({ key: 'actions' })
    }

    const {
      dataMeta,
      perPageOptions,
      refFamilyListTable,

      resolveFamilyAvatarVariant,
    } = useFamilyLists(props)

  const perPage = 6
  const totalFamily = props.totalFamilyList
  const currentPage = props.currentFamilyPage
  const searchQuery = ref('')
  const sortBy = 'id'
  const isSortDirDesc = true
  const statusFilter = ref(null)

  const refetchData = () => 
  {
    props.refresh()
  }

  // return all set value above
  return {
      perPage,
      currentPage,
      totalFamily,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamilyListTable,
      statusFilter,
      refetchData,
      tableColumns,
      // statusOptions,
      // toPage,
      avatarText,
      resolveFamilyAvatarVariant,
      tempFamilyData,
    }
  },
  created(){
    var family = this.$route.fullPath.replaceAll('%20',' ');
    family = family.split('?');
    var familyName = family[2];
    if(familyName !=null){
      this.searchQuery = familyName;
    }

    this.getClientInfo()
  },
  methods: {
    openForm() {
      this.$parent.openForm('add','')
    },
    editFamily(id) {
      this.$parent.openForm('update',id)
      this.$parent.fetchFamilyDetails(id)
    },
    removeFamily(id) {
       this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$parent.removeFamily(id)
        }
      })
    },
    handleSortChange(event) {
      const sortedData = this.list.sort(sortCompare('family'))
      const sortDesc = !false
      if (sortDesc) sortedData.reverse()
      this.familyData = sortedData
    },
    filterBySearch(search){
      this.$parent.filterBySearch(search)
    },
    removePIC(id) {
      const formData = {
        clientId: this.$route.query.id,
        oldPIC: id,
        newPIC: this.newPIC,
        oldPICType: 'Guest Account',
        newPICType: 'Person-In-Charge',
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/removePICDataAction', {
        param: formData,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        this.$refs['removePIC-modal'].hide()

        Swal.fire({
          icon: 'success',
          title: 'PIC Changed Succesfully',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.$parent.fetchData()
        this.initializeNewPICList()
      })
    },
    initializeNewPICList() {
      this.newPICList = []
      this.newPIC = ''
      let temp_array = []

      for (let index = 0; index < this.list.length; index++) {
        if (this.list[index].type !== 'Person-In-Charge') {
          temp_array = {
            // eslint-disable-next-line
            label: this.list[index].firstname + ' ' + this.list[index].lastname,
            id: this.list[index].id,
          }
          this.newPICList.push(temp_array)
        }
      }
    },
    getClientInfo() {
      const formdata = {
        clientId: this.$route.query.id,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/getClientInfo', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        this.clientInfo = response.data.clientInfo
      }, response => {
        //Destroy session
        if(response.status !== 200) {
          this.$router.push({ name: 'login' })
        }
      })
      .catch(error =>  {
          console.log(error.response.status)
      })
    },
    resetAccount(data) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Reset Account!',
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const formData = {
            clientId: this.$route.query.id,
            familyData: data,
          }
          // eslint-disable-next-line
          this.$http.post(this.$apiUrl2 + 'client/resetFamilyAccount', {
            param: formData,
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          })
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Email Sent',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
  },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
th:nth-child(2) {
    width: 183px;
}
.type-pic {
  color: #FF7D01;
  font-size: 12px;
  background: #FFEFE0;
  font-weight: 700;
  padding: 3px 5px;
  text-align: center;
  border-radius: 3px;
}
.type-other {
  color: #7566E8;
  font-size: 12px;
  background: #EEECFD;
  font-weight: 700;
  padding: 3px 5px;
  text-align: center;
  border-radius: 5px;
}
.name-spacing {
  margin-top: 7px;
}
@media (max-width: 767px) {
    .table-responsive .dropdown-menu {
        position: static !important;
    }
}
@media (min-width: 1200px) {
    .table-responsive {
        overflow: inherit;
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
